import React, { useState } from "react";
import "../../App.css"; // Adjusted import path
import logo from "../../images/logo.png";

import { Link } from "react-router-dom";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <header className="header">
        <div className="logo logo-adjust">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo-image" />
          </Link>
        </div>
        <div className="menu-toggle" onClick={handleToggle}>
          <div className={`bar ${isOpen ? "close-bar" : ""}`}></div>
          <div className={`bar ${isOpen ? "close-bar" : ""}`}></div>
          <div className={`bar ${isOpen ? "close-bar" : ""}`}></div>
        </div>

        <nav className={`nav ${isOpen ? "open" : ""}`}>
          <ul className="navbar-right">
            <li>
              <Link to="/" onClick={() => setIsOpen(false)}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/Services" onClick={() => setIsOpen(false)}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/ContactUs" onClick={() => setIsOpen(false)}>
                Contact Us
              </Link>
            </li>
          </ul>
        </nav>
        <div className="contact">Contact Us: +91 8553741736</div>
      </header>
    </div>
  );
};

export default Navigation;
