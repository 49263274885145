import React from "react";

const Services = () => {
  return (
    <div>
      <h1>Welcome to ZMT Services!</h1>

      <div>
        At ZMT, we’re committed to providing the space you want when you expect
        it. we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
      </div>
    </div>
  );
};

export default Services;
