import React from "react";
import { Link } from "react-router-dom";
import address_map from "../../images/address_map.jpg";
import "../../App.css"; // Make sure this points to your CSS file

const ContactUs = () => {
  return (
    <div>
      <h1>Contact Us</h1>

      <div className="contact-container">
        <div className="contact-address">
          <h3>Address:</h3>
          <br /> ZMT Pvt. Ltd.
          <br />
          Sribar, Po. lalang part 2 <br />
          Sribar, Po. lalang part 2 <br />
          Pin no.: 788098
          <br />
          Phone no.: +91 8553741736 (Business) <br />
        </div>
        <div className="contact-map">
          <Link to="https://www.google.com/maps/place/Ukhrul,+Manipur+795142,+Indie/@25.0964821,94.3612445,19.25z/data=!4m15!1m8!3m7!1s0x3748f67980a6a29f:0xcaf347eb55461da0!2sUkhrul,+Manipur+795142,+Indie!3b1!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3!3m5!1s0x3748f67980a6a29f:0xcaf347eb55461da0!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3?entry=ttu">
            <img src={address_map} alt="Address Map" className="map-image" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
