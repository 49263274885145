import React from "react";

const Home = () => {
  return (
    <div>
      <div>
        <h1>About ZMT</h1>
        At ZMT, we’re committed to providing the space you want when you expect
        it. we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        we’re committed to providing the space you want when you expect it.
        <br />
      </div>
    </div>
  );
};

export default Home;
