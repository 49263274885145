/*Login and SignUp show setup:

UserAuthLinks component is passed showLogin, handleLoginClick, and handleSignUpClick props from the parent component (Routers).
ClientLogin and SignUp components call handleSignUpClick and handleLoginClick respectively when their respective links are clicked.
The state for toggling between the "Log In" and "Sign Up" links is managed in the Routers component and propagated down to child components through props.
*/

import { Routes, Route } from "react-router-dom";
import ContactUs from "../Pages/ContactUs";

import Services from "../Pages/Services";
import Home from "../Pages/Home";

const Routers = () => {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/ContactUs" element={<ContactUs />} />
      </Routes>
    </div>
  );
};

export default Routers;
