import React, { Component } from "react";
import { Link } from "react-router-dom";

//import AdminLogin from "../CompLogSign/AdminLogin";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div>{new Date().getFullYear()} &copy; ZMT, All Rights Reserved </div>
        <div className="svg_container">
          <div className="svg_container1">
            <Link to="https://www.google.com/maps/place/Ukhrul,+Manipur+795142,+Indie/@25.0964821,94.3612445,19.25z/data=!4m15!1m8!3m7!1s0x3748f67980a6a29f:0xcaf347eb55461da0!2sUkhrul,+Manipur+795142,+Indie!3b1!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3!3m5!1s0x3748f67980a6a29f:0xcaf347eb55461da0!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3?entry=ttu">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692V10.41h3.128V7.797c0-3.1 1.893-4.785 4.658-4.785 1.325 0 2.463.099 2.797.143v3.24l-1.92.001c-1.504 0-1.793.715-1.793 1.763v2.313h3.587l-.467 3.297h-3.12V24h6.116c.73 0 1.324-.593 1.324-1.324V1.325C24 .593 23.407 0 22.675 0z" />
              </svg>
            </Link>
          </div>
          <div className="svg_container1">
            <Link to="https://www.google.com/maps/place/Ukhrul,+Manipur+795142,+Indie/@25.0964821,94.3612445,19.25z/data=!4m15!1m8!3m7!1s0x3748f67980a6a29f:0xcaf347eb55461da0!2sUkhrul,+Manipur+795142,+Indie!3b1!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3!3m5!1s0x3748f67980a6a29f:0xcaf347eb55461da0!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3?entry=ttu">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.17.057 1.966.24 2.438.512a4.915 4.915 0 0 1 1.773 1.772c.272.472.455 1.268.512 2.438.058 1.266.069 1.646.069 4.85s-.011 3.584-.069 4.85c-.057 1.17-.24 1.966-.512 2.438a4.918 4.918 0 0 1-1.772 1.772c-.472.272-1.268.455-2.438.512-1.266.058-1.646.069-4.85.069s-3.584-.011-4.85-.069c-1.17-.057-1.966-.24-2.438-.512a4.918 4.918 0 0 1-1.772-1.772c-.272-.472-.455-1.268-.512-2.438-.058-1.266-.069-1.646-.069-4.85s.011-3.584.069-4.85c.057-1.17.24-1.966.512-2.438A4.918 4.918 0 0 1 4.71 2.746c.472-.272 1.268-.455 2.438-.512 1.266-.058 1.646-.069 4.85-.069zm0-2.163C8.755 0 8.333.014 7.052.072c-1.319.06-2.48.258-3.468.552a6.919 6.919 0 0 0-2.643 1.106A6.918 6.918 0 0 0 .62 5.52c-.295.988-.493 2.15-.552 3.468C0 10.333 0 10.755 0 12s.014 1.667.072 2.948c.06 1.319.258 2.48.552 3.468a6.918 6.918 0 0 0 1.106 2.643 6.918 6.918 0 0 0 2.643 1.106c.988.295 2.15.493 3.468.552 1.281.058 1.703.072 4.948.072s3.667-.014 4.948-.072c1.319-.06 2.48-.258 3.468-.552a6.919 6.919 0 0 0 2.643-1.106 6.918 6.918 0 0 0 1.106-2.643c.295-.988.493-2.15.552-3.468.058-1.281.072-1.703.072-4.948s-.014-3.667-.072-4.948c-.06-1.319-.258-2.48-.552-3.468a6.918 6.918 0 0 0-1.106-2.643 6.918 6.918 0 0 0-2.643-1.106c-.988-.295-2.15-.493-3.468-.552C15.667.014 15.245 0 12 0zm0 5.838a6.163 6.163 0 1 0 0 12.326 6.163 6.163 0 0 0 0-12.326zm0 10.163a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm6.406-11.845a1.44 1.44 0 1 0 0-2.88 1.44 1.44 0 0 0 0 2.88z" />
              </svg>
            </Link>
          </div>
          <div className="svg_container1">
            <Link to="https://www.google.com/maps/place/Ukhrul,+Manipur+795142,+Indie/@25.0964821,94.3612445,19.25z/data=!4m15!1m8!3m7!1s0x3748f67980a6a29f:0xcaf347eb55461da0!2sUkhrul,+Manipur+795142,+Indie!3b1!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3!3m5!1s0x3748f67980a6a29f:0xcaf347eb55461da0!8m2!3d25.0967563!4d94.3617273!16zL20vMDhkMnN3?entry=ttu">
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path d="M17.472 14.382c-.297-.149-1.758-.868-2.031-.968-.273-.1-.472-.149-.67.15-.198.297-.77.967-.946 1.164-.173.198-.346.223-.643.074-.297-.149-1.256-.463-2.39-1.475-.883-.786-1.48-1.754-1.653-2.051-.173-.297-.018-.458.13-.607.134-.134.297-.347.446-.52.149-.174.198-.298.297-.497.099-.198.05-.372-.025-.521-.075-.149-.671-1.612-.918-2.207-.242-.579-.487-.5-.67-.51h-.573c-.198 0-.52.074-.793.372-.273.297-1.04 1.016-1.04 2.48s1.065 2.874 1.213 3.074c.149.198 2.094 3.2 5.077 4.487.709.306 1.262.489 1.692.626.712.226 1.359.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.413-.075-.124-.273-.198-.571-.347m-5.42 7.617h-.004a9.84 9.84 0 0 1-5.031-1.378l-.361-.214-3.741.982 1.001-3.648-.235-.374a9.84 9.84 0 0 1-1.5-5.253c.003-5.45 4.436-9.884 9.89-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 0 1 2.901 6.994c-.003 5.45-4.436 9.884-9.89 9.884m8.413-18.297A11.815 11.815 0 0 0 12.05.002C5.795.002.634 5.16.63 11.414a11.813 11.813 0 0 0 1.792 6.223L.001 24l6.5-1.707a11.815 11.815 0 0 0 5.56 1.413h.005c6.253 0 11.414-5.158 11.418-11.412a11.81 11.81 0 0 0-3.473-8.413Z" />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
