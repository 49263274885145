import "./App.css";

//import Navigation from "./Components/Navigation";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom"; // Import BrowserRouter
import Header from "./Components/Layouts/Header";
import Footer from "./Components/Layouts/Footer";
import Routers from "./Components/Routers/Routers"; // Import your Routers component
import Banner from "./Components/Banner";
function App() {
  return (
    <div>
      <Router>
        <Header />
        <Banner />
        <Routers />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
